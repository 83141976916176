import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e7d59c28 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _9e61cad6 = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _56b88c24 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _617b0ea1 = () => interopDefault(import('../pages/learn/index.vue' /* webpackChunkName: "pages/learn/index" */))
const _1cc8057a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _c9229784 = () => interopDefault(import('../pages/manage/index.vue' /* webpackChunkName: "pages/manage/index" */))
const _b54326f8 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _72befd48 = () => interopDefault(import('../pages/programs/index.vue' /* webpackChunkName: "pages/programs/index" */))
const _3f917f92 = () => interopDefault(import('../pages/teach/index.vue' /* webpackChunkName: "pages/teach/index" */))
const _90c31b58 = () => interopDefault(import('../pages/admin/competencies/index.vue' /* webpackChunkName: "pages/admin/competencies/index" */))
const _bd079b56 = () => interopDefault(import('../pages/admin/courses/index.vue' /* webpackChunkName: "pages/admin/courses/index" */))
const _3413751b = () => interopDefault(import('../pages/admin/instances/index.vue' /* webpackChunkName: "pages/admin/instances/index" */))
const _6b576678 = () => interopDefault(import('../pages/admin/partners/index.vue' /* webpackChunkName: "pages/admin/partners/index" */))
const _5ab98276 = () => interopDefault(import('../pages/admin/programs.vue' /* webpackChunkName: "pages/admin/programs" */))
const _5b667125 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _34aeebec = () => interopDefault(import('../pages/learn/courses/index.vue' /* webpackChunkName: "pages/learn/courses/index" */))
const _1a52c542 = () => interopDefault(import('../pages/manage/competencies/index.vue' /* webpackChunkName: "pages/manage/competencies/index" */))
const _f3c17ab2 = () => interopDefault(import('../pages/manage/courses/index.vue' /* webpackChunkName: "pages/manage/courses/index" */))
const _7a1346b2 = () => interopDefault(import('../pages/manage/partners/index.vue' /* webpackChunkName: "pages/manage/partners/index" */))
const _4773d776 = () => interopDefault(import('../pages/manage/programs/index.vue' /* webpackChunkName: "pages/manage/programs/index" */))
const _5a890c0a = () => interopDefault(import('../pages/teach/courses/index.vue' /* webpackChunkName: "pages/teach/courses/index" */))
const _57ba2dbc = () => interopDefault(import('../pages/manage/competencies/create.vue' /* webpackChunkName: "pages/manage/competencies/create" */))
const _657d5cf7 = () => interopDefault(import('../pages/manage/courses/create.vue' /* webpackChunkName: "pages/manage/courses/create" */))
const _1fec4b68 = () => interopDefault(import('../pages/manage/partners/create.vue' /* webpackChunkName: "pages/manage/partners/create" */))
const _62893bf0 = () => interopDefault(import('../pages/manage/programs/create.vue' /* webpackChunkName: "pages/manage/programs/create" */))
const _27f45a2c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3c406e87 = () => interopDefault(import('../pages/learn/courses/_code/index.vue' /* webpackChunkName: "pages/learn/courses/_code/index" */))
const _6b6f5fbf = () => interopDefault(import('../pages/manage/competencies/_code/index.vue' /* webpackChunkName: "pages/manage/competencies/_code/index" */))
const _17ff7d64 = () => interopDefault(import('../pages/manage/courses/_code/index.vue' /* webpackChunkName: "pages/manage/courses/_code/index" */))
const _23c50d2f = () => interopDefault(import('../pages/manage/partners/_code/index.vue' /* webpackChunkName: "pages/manage/partners/_code/index" */))
const _6abefaf3 = () => interopDefault(import('../pages/manage/programs/_code/index.vue' /* webpackChunkName: "pages/manage/programs/_code/index" */))
const _51e1e5b8 = () => interopDefault(import('../pages/teach/courses/_code/index.vue' /* webpackChunkName: "pages/teach/courses/_code/index" */))
const _7c0c9869 = () => interopDefault(import('../pages/learn/courses/_code/assessments/index.vue' /* webpackChunkName: "pages/learn/courses/_code/assessments/index" */))
const _bc446cce = () => interopDefault(import('../pages/learn/courses/_code/competencies/index.vue' /* webpackChunkName: "pages/learn/courses/_code/competencies/index" */))
const _5d8ad0a2 = () => interopDefault(import('../pages/learn/courses/_code/evaluations/index.vue' /* webpackChunkName: "pages/learn/courses/_code/evaluations/index" */))
const _2edb60a6 = () => interopDefault(import('../pages/manage/competencies/_code/edit.vue' /* webpackChunkName: "pages/manage/competencies/_code/edit" */))
const _7a797874 = () => interopDefault(import('../pages/manage/courses/_code/assessments/index.vue' /* webpackChunkName: "pages/manage/courses/_code/assessments/index" */))
const _13352d30 = () => interopDefault(import('../pages/manage/courses/_code/edit.vue' /* webpackChunkName: "pages/manage/courses/_code/edit" */))
const _912c8096 = () => interopDefault(import('../pages/manage/courses/_code/groups/index.vue' /* webpackChunkName: "pages/manage/courses/_code/groups/index" */))
const _d3992a22 = () => interopDefault(import('../pages/manage/courses/_code/registrations/index.vue' /* webpackChunkName: "pages/manage/courses/_code/registrations/index" */))
const _4fef040e = () => interopDefault(import('../pages/manage/programs/_code/edit.vue' /* webpackChunkName: "pages/manage/programs/_code/edit" */))
const _0324823e = () => interopDefault(import('../pages/manage/programs/_code/registrations/index.vue' /* webpackChunkName: "pages/manage/programs/_code/registrations/index" */))
const _917063cc = () => interopDefault(import('../pages/teach/courses/_code/assessments/index.vue' /* webpackChunkName: "pages/teach/courses/_code/assessments/index" */))
const _3fc4814b = () => interopDefault(import('../pages/teach/courses/_code/calendar/index.vue' /* webpackChunkName: "pages/teach/courses/_code/calendar/index" */))
const _0c75cd60 = () => interopDefault(import('../pages/teach/courses/_code/evaluations/index.vue' /* webpackChunkName: "pages/teach/courses/_code/evaluations/index" */))
const _50a07daf = () => interopDefault(import('../pages/teach/courses/_code/learners/index.vue' /* webpackChunkName: "pages/teach/courses/_code/learners/index" */))
const _854d0090 = () => interopDefault(import('../pages/manage/courses/_code/assessments/create.vue' /* webpackChunkName: "pages/manage/courses/_code/assessments/create" */))
const _ac0fad44 = () => interopDefault(import('../pages/teach/courses/_code/evaluations/create.vue' /* webpackChunkName: "pages/teach/courses/_code/evaluations/create" */))
const _70a156d4 = () => interopDefault(import('../pages/learn/courses/_code/assessments/_id/index.vue' /* webpackChunkName: "pages/learn/courses/_code/assessments/_id/index" */))
const _7492431a = () => interopDefault(import('../pages/learn/courses/_code/evaluations/_id/index.vue' /* webpackChunkName: "pages/learn/courses/_code/evaluations/_id/index" */))
const _49ade89e = () => interopDefault(import('../pages/manage/courses/_code/assessments/_id/index.vue' /* webpackChunkName: "pages/manage/courses/_code/assessments/_id/index" */))
const _1ec68f02 = () => interopDefault(import('../pages/teach/courses/_code/assessments/_id.vue' /* webpackChunkName: "pages/teach/courses/_code/assessments/_id" */))
const _e22d8f6a = () => interopDefault(import('../pages/teach/courses/_code/evaluations/_id/index.vue' /* webpackChunkName: "pages/teach/courses/_code/evaluations/_id/index" */))
const _765c27d6 = () => interopDefault(import('../pages/teach/courses/_code/learners/_username/index.vue' /* webpackChunkName: "pages/teach/courses/_code/learners/_username/index" */))
const _729b8ca6 = () => interopDefault(import('../pages/learn/courses/_code/assessments/_id/requestEvaluation.vue' /* webpackChunkName: "pages/learn/courses/_code/assessments/_id/requestEvaluation" */))
const _bc4b450a = () => interopDefault(import('../pages/manage/courses/_code/assessments/_id/edit.vue' /* webpackChunkName: "pages/manage/courses/_code/assessments/_id/edit" */))
const _3d1594be = () => interopDefault(import('../pages/teach/courses/_code/evaluations/_id/edit.vue' /* webpackChunkName: "pages/teach/courses/_code/evaluations/_id/edit" */))
const _0c0f9e12 = () => interopDefault(import('../pages/teach/courses/_code/learners/_username/assessments/index.vue' /* webpackChunkName: "pages/teach/courses/_code/learners/_username/assessments/index" */))
const _77fc1ae2 = () => interopDefault(import('../pages/learn/courses/_code/assessments/_id/take/_iid.vue' /* webpackChunkName: "pages/learn/courses/_code/assessments/_id/take/_iid" */))
const _18fc051f = () => interopDefault(import('../pages/teach/courses/_code/learners/_username/assessments/_id.vue' /* webpackChunkName: "pages/teach/courses/_code/learners/_username/assessments/_id" */))
const _9acc68e2 = () => interopDefault(import('../pages/courses/_code.vue' /* webpackChunkName: "pages/courses/_code" */))
const _b1adc504 = () => interopDefault(import('../pages/partners/_code.vue' /* webpackChunkName: "pages/partners/_code" */))
const _6681ee69 = () => interopDefault(import('../pages/profiles/_username/index.vue' /* webpackChunkName: "pages/profiles/_username/index" */))
const _7489ae42 = () => interopDefault(import('../pages/programs/_code.vue' /* webpackChunkName: "pages/programs/_code" */))
const _3afe43c3 = () => interopDefault(import('../pages/profiles/_username/edit.vue' /* webpackChunkName: "pages/profiles/_username/edit" */))
const _57aa5a0e = () => interopDefault(import('../pages/profiles/_username/passwordreset/_token.vue' /* webpackChunkName: "pages/profiles/_username/passwordreset/_token" */))
const _67dcf204 = () => interopDefault(import('../pages/profiles/_username/_emailConfirmationToken.vue' /* webpackChunkName: "pages/profiles/_username/_emailConfirmationToken" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin/",
    component: _e7d59c28,
    pathToRegexpOptions: {"strict":true},
    name: "admin"
  }, {
    path: "/courses/",
    component: _9e61cad6,
    pathToRegexpOptions: {"strict":true},
    name: "courses"
  }, {
    path: "/dashboard/",
    component: _56b88c24,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard"
  }, {
    path: "/learn/",
    component: _617b0ea1,
    pathToRegexpOptions: {"strict":true},
    name: "learn"
  }, {
    path: "/login/",
    component: _1cc8057a,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/manage/",
    component: _c9229784,
    pathToRegexpOptions: {"strict":true},
    name: "manage"
  }, {
    path: "/partners/",
    component: _b54326f8,
    pathToRegexpOptions: {"strict":true},
    name: "partners"
  }, {
    path: "/programs/",
    component: _72befd48,
    pathToRegexpOptions: {"strict":true},
    name: "programs"
  }, {
    path: "/teach/",
    component: _3f917f92,
    pathToRegexpOptions: {"strict":true},
    name: "teach"
  }, {
    path: "/admin/competencies/",
    component: _90c31b58,
    pathToRegexpOptions: {"strict":true},
    name: "admin-competencies"
  }, {
    path: "/admin/courses/",
    component: _bd079b56,
    pathToRegexpOptions: {"strict":true},
    name: "admin-courses"
  }, {
    path: "/admin/instances/",
    component: _3413751b,
    pathToRegexpOptions: {"strict":true},
    name: "admin-instances"
  }, {
    path: "/admin/partners/",
    component: _6b576678,
    pathToRegexpOptions: {"strict":true},
    name: "admin-partners"
  }, {
    path: "/admin/programs/",
    component: _5ab98276,
    pathToRegexpOptions: {"strict":true},
    name: "admin-programs"
  }, {
    path: "/admin/users/",
    component: _5b667125,
    pathToRegexpOptions: {"strict":true},
    name: "admin-users"
  }, {
    path: "/learn/courses/",
    component: _34aeebec,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses"
  }, {
    path: "/manage/competencies/",
    component: _1a52c542,
    pathToRegexpOptions: {"strict":true},
    name: "manage-competencies"
  }, {
    path: "/manage/courses/",
    component: _f3c17ab2,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses"
  }, {
    path: "/manage/partners/",
    component: _7a1346b2,
    pathToRegexpOptions: {"strict":true},
    name: "manage-partners"
  }, {
    path: "/manage/programs/",
    component: _4773d776,
    pathToRegexpOptions: {"strict":true},
    name: "manage-programs"
  }, {
    path: "/teach/courses/",
    component: _5a890c0a,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses"
  }, {
    path: "/manage/competencies/create/",
    component: _57ba2dbc,
    pathToRegexpOptions: {"strict":true},
    name: "manage-competencies-create"
  }, {
    path: "/manage/courses/create/",
    component: _657d5cf7,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-create"
  }, {
    path: "/manage/partners/create/",
    component: _1fec4b68,
    pathToRegexpOptions: {"strict":true},
    name: "manage-partners-create"
  }, {
    path: "/manage/programs/create/",
    component: _62893bf0,
    pathToRegexpOptions: {"strict":true},
    name: "manage-programs-create"
  }, {
    path: "/",
    component: _27f45a2c,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/learn/courses/:code/",
    component: _3c406e87,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code"
  }, {
    path: "/manage/competencies/:code/",
    component: _6b6f5fbf,
    pathToRegexpOptions: {"strict":true},
    name: "manage-competencies-code"
  }, {
    path: "/manage/courses/:code/",
    component: _17ff7d64,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code"
  }, {
    path: "/manage/partners/:code/",
    component: _23c50d2f,
    pathToRegexpOptions: {"strict":true},
    name: "manage-partners-code"
  }, {
    path: "/manage/programs/:code/",
    component: _6abefaf3,
    pathToRegexpOptions: {"strict":true},
    name: "manage-programs-code"
  }, {
    path: "/teach/courses/:code/",
    component: _51e1e5b8,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code"
  }, {
    path: "/learn/courses/:code/assessments/",
    component: _7c0c9869,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-assessments"
  }, {
    path: "/learn/courses/:code/competencies/",
    component: _bc446cce,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-competencies"
  }, {
    path: "/learn/courses/:code/evaluations/",
    component: _5d8ad0a2,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-evaluations"
  }, {
    path: "/manage/competencies/:code/edit/",
    component: _2edb60a6,
    pathToRegexpOptions: {"strict":true},
    name: "manage-competencies-code-edit"
  }, {
    path: "/manage/courses/:code/assessments/",
    component: _7a797874,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-assessments"
  }, {
    path: "/manage/courses/:code/edit/",
    component: _13352d30,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-edit"
  }, {
    path: "/manage/courses/:code/groups/",
    component: _912c8096,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-groups"
  }, {
    path: "/manage/courses/:code/registrations/",
    component: _d3992a22,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-registrations"
  }, {
    path: "/manage/programs/:code/edit/",
    component: _4fef040e,
    pathToRegexpOptions: {"strict":true},
    name: "manage-programs-code-edit"
  }, {
    path: "/manage/programs/:code/registrations/",
    component: _0324823e,
    pathToRegexpOptions: {"strict":true},
    name: "manage-programs-code-registrations"
  }, {
    path: "/teach/courses/:code/assessments/",
    component: _917063cc,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-assessments"
  }, {
    path: "/teach/courses/:code/calendar/",
    component: _3fc4814b,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-calendar"
  }, {
    path: "/teach/courses/:code/evaluations/",
    component: _0c75cd60,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-evaluations"
  }, {
    path: "/teach/courses/:code/learners/",
    component: _50a07daf,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-learners"
  }, {
    path: "/manage/courses/:code/assessments/create/",
    component: _854d0090,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-assessments-create"
  }, {
    path: "/teach/courses/:code/evaluations/create/",
    component: _ac0fad44,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-evaluations-create"
  }, {
    path: "/learn/courses/:code/assessments/:id/",
    component: _70a156d4,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-assessments-id"
  }, {
    path: "/learn/courses/:code/evaluations/:id/",
    component: _7492431a,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-evaluations-id"
  }, {
    path: "/manage/courses/:code/assessments/:id/",
    component: _49ade89e,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-assessments-id"
  }, {
    path: "/teach/courses/:code/assessments/:id/",
    component: _1ec68f02,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-assessments-id"
  }, {
    path: "/teach/courses/:code/evaluations/:id/",
    component: _e22d8f6a,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-evaluations-id"
  }, {
    path: "/teach/courses/:code/learners/:username/",
    component: _765c27d6,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-learners-username"
  }, {
    path: "/learn/courses/:code/assessments/:id/requestEvaluation/",
    component: _729b8ca6,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-assessments-id-requestEvaluation"
  }, {
    path: "/manage/courses/:code/assessments/:id/edit/",
    component: _bc4b450a,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-assessments-id-edit"
  }, {
    path: "/teach/courses/:code/evaluations/:id/edit/",
    component: _3d1594be,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-evaluations-id-edit"
  }, {
    path: "/teach/courses/:code/learners/:username/assessments/",
    component: _0c0f9e12,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-learners-username-assessments"
  }, {
    path: "/learn/courses/:code/assessments/:id/take/:iid?/",
    component: _77fc1ae2,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-assessments-id-take-iid"
  }, {
    path: "/teach/courses/:code/learners/:username/assessments/:id/",
    component: _18fc051f,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-learners-username-assessments-id"
  }, {
    path: "/courses/:code/",
    component: _9acc68e2,
    pathToRegexpOptions: {"strict":true},
    name: "courses-code"
  }, {
    path: "/partners/:code/",
    component: _b1adc504,
    pathToRegexpOptions: {"strict":true},
    name: "partners-code"
  }, {
    path: "/profiles/:username/",
    component: _6681ee69,
    pathToRegexpOptions: {"strict":true},
    name: "profiles-username"
  }, {
    path: "/programs/:code/",
    component: _7489ae42,
    pathToRegexpOptions: {"strict":true},
    name: "programs-code"
  }, {
    path: "/profiles/:username?/edit/",
    component: _3afe43c3,
    pathToRegexpOptions: {"strict":true},
    name: "profiles-username-edit"
  }, {
    path: "/profiles/:username?/passwordreset/:token?/",
    component: _57aa5a0e,
    pathToRegexpOptions: {"strict":true},
    name: "profiles-username-passwordreset-token"
  }, {
    path: "/profiles/:username?/:emailConfirmationToken/",
    component: _67dcf204,
    pathToRegexpOptions: {"strict":true},
    name: "profiles-username-emailConfirmationToken"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
