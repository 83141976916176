import { render, staticRenderFns } from "./default.vue?vue&type=template&id=c6e4bf32&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=c6e4bf32&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SignUpBtn: require('/home/combefis/tlca/tlca-frontend/components/homespace/SignUpBtn.vue').default,SignInBtn: require('/home/combefis/tlca/tlca-frontend/components/homespace/SignInBtn.vue').default,UserMenu: require('/home/combefis/tlca/tlca-frontend/components/UserMenu.vue').default,SpaceNavigator: require('/home/combefis/tlca/tlca-frontend/components/SpaceNavigator.vue').default,NotificationDisplayer: require('/home/combefis/tlca/tlca-frontend/components/NotificationDisplayer.vue').default,BreadCrumb: require('/home/combefis/tlca/tlca-frontend/components/BreadCrumb.vue').default,LanguageSwitcher: require('/home/combefis/tlca/tlca-frontend/components/LanguageSwitcher.vue').default})
